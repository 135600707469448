<template>
  <div>
    <div>
      <div class="newsLook">
        <el-form
          label-width="120px"
          :model="newsData"
          :rules="newsDataRules"
          ref="technologyRef"
        >
          <el-form-item label="技术名称：" prop="techName">
            <el-input v-model="newsData.techName"></el-input>
          </el-form-item>
          <el-form-item label="英文技术名称：">
            <el-input v-model="newsData.techNameEg"></el-input>
          </el-form-item>
          <el-form-item label="简称：">
            <el-input v-model="newsData.abbreviation"></el-input>
          </el-form-item>
          <el-form-item label="简介：">
            <el-input
              v-model="newsData.briefIntroduction"
              type="textarea"
              :autosize="{ minRows: 4, maxRows: 20 }"
            ></el-input>
          </el-form-item>
          <el-form-item label="英文简介：">
            <el-input
              v-model="newsData.briefIntroductionEg"
              type="textarea"
              :autosize="{ minRows: 4, maxRows: 20 }"
            ></el-input>
          </el-form-item>
          <el-form-item label="详细介绍：">
            <el-input
              v-model="newsData.detailedIntroduction"
              type="textarea"
              :autosize="{ minRows: 4, maxRows: 20 }"
            ></el-input>
          </el-form-item>
          <el-form-item label="英文详细介绍：">
            <el-input
              v-model="newsData.detailedIntroductionEg"
              type="textarea"
              :autosize="{ minRows: 4, maxRows: 20 }"
            ></el-input>
          </el-form-item>
          <el-form-item label="技术检索：">
            <!-- <el-input v-model="newsData.fatherId"></el-input> -->
            <el-select
              v-model="newsData.father"
              filterable
              placeholder="请输入"
              @change="selectOne"
              remote
              reserve-keyword
              :remote-method="searchTechnologys"
            >
              <el-option
                v-for="item in options"
                :key="item.id"
                :label="item.techName"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="上级技术：">
            <p
              v-if="newsData.fatherId == '0' && !newsData.knowTech.length > 0"
              style="color: #7c7f8e;"
            >
              该标签为一级标签
            </p>
            <el-tag
              v-for="tag in newsData.knowTech"
              :key="tag.id"
              closable
              @close="handleClose(tag)"
              style="margin-right:10px"
              v-else
              >{{ tag.techName }}</el-tag
            >
          </el-form-item>
          <el-form-item label="下级技术：" v-if="personType">
            <el-input
              v-model="list"
              type="textarea"
              placeholder="该标签无下级标签"
              :autosize="{ minRows: 4, maxRows: 8 }"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="技术类型：" prop="technologyType">
            <dic-radio-group
              :code.sync="newsData.technologyType"
              type-code="025"
              query-name="cooperationStatus"
              name="技术类型"
            />
          </el-form-item>
          <el-form-item label="筛选标签：" prop="screen">
            <el-radio-group v-model="newsData.screen">
              <el-radio :label="1">是</el-radio>
              <el-radio :label="0">否</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
        <div class="company_right" v-if="personType">
          <div>创建人：{{ newsData.createPrson }}</div>
          <div>修改人：{{ newsData.alterPrson }}</div>
          <div>创建时间：{{ newsData.comIncludedTime }}</div>
          <div>修改时间：{{ newsData.comUpdateTime }}</div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <el-button type="primary" @click="btnClick">确定</el-button>
      <el-button @click="cancelItem">返回</el-button>
    </div>
  </div>
</template>

<script>
import DicRadioGroup from "@/components/DicRadioGroup.vue";
import DicRadioButton from "@/components/DicRadioButton.vue";
import { getKnowTechList } from "@/api/common";
import {
  editTechnology,
  addTechnology,
  getTechnologyOne,
  searchTechnology
} from "@/api/technology";
export default {
  name: "newsInfo",
  components: {
    DicRadioGroup,
    DicRadioButton
  },
  computed: {
    newsDatas() {
      return Number(this.$route.query.newsDatas);
    },
    personType() {
      return Number(this.$route.query.personType);
    },
    Authorization() {
      return sessionStorage.getItem("token");
    }
  },
  created() {
    // this.searchTechnologys();
    if (this.personType) {
      this.getTechnologyOnes();
    }
  },
  data() {
    return {
      newsData: {
        // 技术名称
        techName: "",
        // 英文技术名称
        techNameEg: "",
        // 简称
        abbreviation: "",
        // 简介
        briefIntroduction: "",
        // 英文简介
        briefIntroductionEg: "",
        // 详细介绍
        detailedIntroduction: "",
        // 英文详细介绍
        detailedIntroductionEg: "",
        // 发展史
        develHistory: "",
        // 结构
        structure: "",
        // 公司收录时间
        comIncludedTime: "",
        // 公司更新时间
        comUpdateTime: "",
        // 运用领域和方向
        applicationFieldDirection: "",
        // 父节点
        fatherId: null,
        // 技术类型
        technologyType: "",
        // 是否为筛选标签
        screen: null,
        knowTech: []
      },
      options: [],
      newsDataRules: {
        techName: [
          { required: true, message: "请输入技术名称", trigger: "blur" }
        ],
        // briefIntroduction: [
        //   { required: true, message: "请输入简介", trigger: "blur" },
        // ],
        technologyType: [
          { required: true, message: "请填写技术类型", trigger: "blur" }
        ],
        screen: [{ required: true, message: "请填写筛选标签", trigger: "blur" }]
      },
      data: "",
      queryInfo: {
        positionType: ""
      },
      list: ""
    };
  },
  methods: {
    // 查询单个接口
    async getTechnologyOnes() {
      const params = {
        id: this.newsDatas
      };
      const res = await getTechnologyOne(params);
      this.newsData = res.data;
      const data = this.newsData.knowTecheslist;
      this.list = data.map(item => {
        return (item = item.techName);
      });
      this.list = this.list.join("|");
      if (!this.newsData.knowTech) {
        this.newsData.knowTech = [];
      }
    },
    cancelItem() {
      this.$router.go(-1);
    },
    async btnClick() {
      //修改
      Object.assign(this.newsData, {
        Authorization: this.Authorization
      });
      this.$refs.technologyRef.validate(async valid => {
        if (valid) {
          if (!this.newsData.knowTech.length > 0) {
            this.newsData.knowTech = [{ id: 0 }];
          }
          if (this.personType) {
            const res = await editTechnology(this.newsData);
            if (res.code == 200) {
              this.$message.success("修改成功");
              this.$router.go(-1);
            } else {
              this.$message.error(res.msg);
            }
          } else {
            // 新增
            if (this.newsData.fatherId == null) {
              this.newsData.fatherId = 0;
            }
            const res = await addTechnology(this.newsData);
            if (res.code == 200) {
              this.$message.success("新增成功");
              this.$router.go(-1);
            } else {
              this.$message.error(res.msg);
            }
          }
          const res1 = await getKnowTechList();
          res1.data = JSON.parse(
            JSON.stringify(res1.data).replace(/techName/g, "codeName")
          );
          sessionStorage.setItem("tech", JSON.stringify(res1.data));
        }
      });
    },
    async searchTechnologys(query) {
      if (query !== "") {
        setTimeout(async () => {
          const res = await searchTechnology({ techName: query });
          const dataList = res.data;
          this.options = dataList.map(item => {
            return (item = {
              id: item.id,
              techName: item.techName
            });
          });
        }, 200);
      } else {
        this.options = [];
      }
      // const res = await searchTechnology();
      // const dataList = res.data;
      // this.options = dataList.map((item) => {
      //   return (item = {
      //     id: item.id,
      //     techName: item.techName,
      //   });
      // });
    },
    selectOne() {
      const valueOne = this.options.filter(
        item => item.id === this.newsData.father
      );
      this.newsData.knowTech.push(valueOne[0]);
    },
    handleClose(tag) {
      const index = this.newsData.knowTech.indexOf(tag);
      this.newsData.knowTech.splice(index, 1);
    }
  },
  mounted() {
    document.getElementsByClassName("newsLook")[0].style.height =
      document.body.clientHeight - 220 + "px";
  }
};
</script>

<style lang="less" scoped>
.newsLook {
  background-color: white;
  padding: 30px 10px 10px 10px;
  overflow: auto;
  // height: 720px;
  .el-form {
    .el-form-item {
      margin-bottom: 20px;
      .el-textarea {
        width: 80%;
      }
      .el-input {
        width: 470px;
      }
      /deep/ .el-textarea__inner {
        height: 100%;
      }
    }
  }
}
.bottom {
  border-top: 1px solid #dbdbdb;
  background: #fff;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  .el-button {
    margin: 0 20px;
  }
}
/deep/ .el-textarea.is-disabled .el-textarea__inner {
  background-color: white;
  color: #606266;
  cursor: default;
}
.company_right {
  margin: 0px 40px;
  font-size: 13px;
  color: #7c7f8e;
}
</style>
