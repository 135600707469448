import request from "@/utils/request";

// 查询技术知识
export function getTechnology(data) {
  return request({
    url: "/system/knowTech/getKnowTechPageTypeList",
    method: "post",
    data
  });
}

// 修改
export function editTechnology(data) {
  return request({
    url: "/system/knowTech/updateKnowTech",
    method: "post",
    data
  });
}

// 新增
export function addTechnology(data) {
  return request({
    url: "/system/knowTech/insertKnowTech",
    method: "post",
    data
  });
}

// 单个查询
export function getTechnologyOne(data) {
  return request({
    url: "/system/knowTech/getKnowTechByid",
    method: "get",
    params: data
  });
}

// 删除
export function deleteTechnology(data) {
  return request({
    url: "/system/knowTech/deleteKnowTechByid",
    method: "post",
    data
  });
}

// 查询一二级标签
export function searchTechnology(data) {
    return request({
        url: "/system/knowTech/getComboBox",
        method: "get",
        params:data
    });
}

// 新增
export function technology(data) {
    return request({
      url: "/system/knowTech/importTech",
      method: "post",
      data
    });
  }
  export function importdata(data) {
    return request({
        url: "/system/scenarioBasic/import",
        method: "post",
        data
    });
}

 